import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../hooks/use-locale';
import {
  DisplayCardContainerLinkResults,
  DisplayCardTitle,
  DisplayCardIcon,
} from './DisplayCard';
import { PaddingComponent } from '../atoms/PaddingComponent';
import { Grid, GridColumn } from './Grid';
import { Id, SongStoryTitleContainer } from './SongStory.style';
import { TileDetail } from './TileDetail';
import { ESLayType } from '../types/ElasticResponse';
import { TopicIcon } from '../icons/CardIcons';

interface LayTypeCardProps {
  result: ESLayType;
}

export const LayTypeCard: React.FC<LayTypeCardProps> = ({ result }) => {
  const locale = useLocale() || 'en';
  const { t } = useTranslation();

  function getTitleForLocale(laytype: ESLayType): string {
    if (locale === 'ga') {
      return laytype.title_ga;
    } else if (locale === 'gd') {
      return laytype.title_gd;
    }
    return laytype.title_en;
  }

  function getAltTitlesForLocale(laytype: ESLayType): string[] {
    let titles = [];
    for (const altTitle of laytype.alternative_titles) {
      if (locale === 'ga') {
        titles.push(altTitle.alt_title_ga);
      } else if (locale === 'gd') {
        titles.push(altTitle.alt_title_gd);
      } else {
        titles.push(altTitle.alt_title_en);
      }
    }
    return titles;
  }

  return (
    <DisplayCardContainerLinkResults
      to={`/lays/${result.slug}`}
      target={'_blank'}
      rel={'noopener noreferrer'}
      backgroundcolor="#eefffd"
    >
      <SongStoryTitleContainer>
        <DisplayCardTitle>{getTitleForLocale(result)}</DisplayCardTitle>
        <DisplayCardIcon>
          {t('Story/Song')}
          <TopicIcon />
        </DisplayCardIcon>
      </SongStoryTitleContainer>
      <PaddingComponent />
      <Grid>
        <GridColumn>
          {result.alternative_titles.length > 0 && (
            <TileDetail label={t('Alternative Titles')}>
              {getAltTitlesForLocale(result).map((title, index) => (
                <div key={index}>{title}</div>
              ))}
            </TileDetail>
          )}

          {result.international_tale_type && (
            <TileDetail label={t('International Tale Type')}>
              {result.international_tale_type}
            </TileDetail>
          )}
        </GridColumn>
      </Grid>
    </DisplayCardContainerLinkResults>
  );
};
