import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ElasticResponse, getAggBucketArray } from '../types/ElasticResponse';
import { useLocale } from '../hooks/use-locale';
import { Collapsible } from '../components/Collapsible';
import { Checkbox, CheckboxGroup, Select } from '../atoms/CustomForms';
import { personFields, getGenderLabel } from '../utils/esFieldNames';

const HrMargin = styled.hr`
  margin: 1em;
`;

interface PersonFacetsProps {
  searchResults: ElasticResponse;
  searchParams: URLSearchParams;
}

export const PersonFacets: React.FC<PersonFacetsProps> = ({
  searchResults,
  searchParams,
}) => {
  const { t } = useTranslation();
  const locale = useLocale() || 'en';
  const languageLookup: { [key: string]: string } = {
    en: 'English',
    ga: 'Irish',
    gd: 'Scottish Gaelic',
    gv: 'Manx',
  };

  

  return (
    <>
      <HrMargin />

      {getAggBucketArray(searchResults, personFields.dateOfBirth.agg).length >
        0 && (
        <Select
          name={personFields.dateOfBirth.name}
          label={t(personFields.dateOfBirth.label)}
          options={getAggBucketArray(searchResults, personFields.dateOfBirth.agg).map((field) => {
            return {
              value: field.key,
              name: `${field.key} (${field.doc_count})`,
            };
          })}
          selected={searchParams.get(personFields.dateOfBirth.name)}
        />
      )}

      {getAggBucketArray(searchResults, personFields.role.agg).length > 0 && (
        <Select
          name={personFields.role.name}
          label={t(personFields.role.label)}
          options={getAggBucketArray(searchResults, personFields.role.agg).map((field) => {
            return {
              value: field.key,
              name: `${field.key} (${field.doc_count})`,
            };
          })}
          selected={searchParams.get(personFields.role.name)}
        />
      )}
      {getAggBucketArray(searchResults, personFields.gender.agg).length > 0 && (
        <Select
          name={personFields.gender.name}
          label={t(personFields.gender.label)}
          options={getAggBucketArray(searchResults, personFields.gender.agg).map((field) => {
            return {
              value: field.key,
              name: `${t(getGenderLabel(field.key))} (${field.doc_count})`,
            };
          })}
          selected={searchParams.get(personFields.gender.name)}
        />
      )}

      <Collapsible
        label={t('Birthplace')}
        open={
          searchParams.has(personFields.countryOfBirth[locale].name) ||
          searchParams.has(personFields.regionOfBirth[locale].name) ||
          searchParams.has(personFields.countyOfBirth[locale].name) ||
          searchParams.has(personFields.baronyOfBirth[locale].name) ||
          searchParams.has(personFields.parishOfBirth[locale].name) ||
          searchParams.has(personFields.townOfBirth[locale].name)
        }
        arrow={true}
      >
        {getAggBucketArray(searchResults, personFields.countryOfBirth[locale].agg).length > 0 && (
          <Select
            name={personFields.countryOfBirth[locale].name}
            label={t(personFields.countryOfBirth.label)}
            options={getAggBucketArray(searchResults, personFields.countryOfBirth[locale].agg).map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(
              personFields.countryOfBirth[locale].name
            )}
          />
        )}
        {getAggBucketArray(searchResults, personFields.regionOfBirth[locale].agg).length > 0 && (
          <Select
            name={personFields.regionOfBirth[locale].name}
            label={t(personFields.regionOfBirth.label)}
            options={getAggBucketArray(searchResults, personFields.regionOfBirth[locale].agg).map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.regionOfBirth[locale].name)}
          />
        )}
        {getAggBucketArray(searchResults, personFields.countyOfBirth[locale].agg).length > 0 && (
          <Select
            name={personFields.countyOfBirth[locale].name}
            label={t(personFields.countyOfBirth.label)}
            options={getAggBucketArray(searchResults, personFields.countyOfBirth[locale].agg).map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.countyOfBirth[locale].name)}
          />
        )}
        {getAggBucketArray(searchResults, personFields.baronyOfBirth[locale].agg).length > 0 && (
          <Select
            name={personFields.baronyOfBirth[locale].name}
            label={t(personFields.baronyOfBirth.label)}
            options={getAggBucketArray(searchResults, personFields.baronyOfBirth[locale].agg).map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.baronyOfBirth[locale].name)}
          />
        )}
        {getAggBucketArray(searchResults, personFields.parishOfBirth[locale].agg).length > 0 && (
          <Select
            name={personFields.parishOfBirth[locale].name}
            label={t(personFields.parishOfBirth.label)}
            options={getAggBucketArray(searchResults, personFields.parishOfBirth[locale].agg).map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.parishOfBirth[locale].name)}
          />
        )}
        {getAggBucketArray(searchResults, personFields.townOfBirth[locale].agg).length > 0 && (
          <Select
            name={personFields.townOfBirth[locale].name}
            label={t(personFields.townOfBirth.label)}
            options={getAggBucketArray(searchResults, personFields.townOfBirth[locale].agg).map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(personFields.townOfBirth[locale].name)}
          />
        )}
      </Collapsible>

      <Collapsible
        label={t('Place of Residence')}
        open={
          searchParams.has(personFields.countryOfResidence[locale].name) ||
          searchParams.has(personFields.regionOfResidence[locale].name) ||
          searchParams.has(personFields.countyOfResidence[locale].name) ||
          searchParams.has(personFields.baronyOfResidence[locale].name) ||
          searchParams.has(personFields.parishOfResidence[locale].name) ||
          searchParams.has(personFields.townOfResidence[locale].name)
        }
        arrow={true}
      >
        {getAggBucketArray(searchResults, personFields.countryOfResidence[locale].agg).length > 0 && (
          <Select
            name={personFields.countryOfResidence[locale].name}
            label={t(personFields.countryOfResidence.label)}
            options={getAggBucketArray(searchResults, personFields.countryOfResidence[locale].agg).map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(
              personFields.countryOfResidence[locale].name
            )}
          />
        )}

        {getAggBucketArray(searchResults, personFields.regionOfResidence[locale].agg).length > 0 && (
          <Select
            name={personFields.regionOfResidence[locale].name}
            label={t(personFields.regionOfResidence.label)}
            options={getAggBucketArray(searchResults, personFields.regionOfResidence[locale].agg).map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(
              personFields.regionOfResidence[locale].name
            )}
          />
        )}

        {getAggBucketArray(searchResults, personFields.countyOfResidence[locale].agg).length > 0 && (
          <Select
            name={personFields.countyOfResidence[locale].name}
            label={t(personFields.countyOfResidence.label)}
            options={getAggBucketArray(searchResults, personFields.countyOfResidence[locale].agg).map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(
              personFields.countyOfResidence[locale].name
            )}
          />
        )}

        {getAggBucketArray(searchResults, personFields.baronyOfResidence[locale].agg).length > 0 && (
          <Select
            name={personFields.baronyOfResidence[locale].name}
            label={t(personFields.baronyOfResidence.label)}
            options={getAggBucketArray(searchResults, personFields.baronyOfResidence[locale].agg).map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(
              personFields.baronyOfResidence[locale].name
            )}
          />
        )}

        {getAggBucketArray(searchResults, personFields.parishOfResidence[locale].agg).length > 0 && (
          <Select
            name={personFields.parishOfResidence[locale].name}
            label={t(personFields.parishOfResidence.label)}
            options={getAggBucketArray(searchResults, personFields.parishOfResidence[locale].agg).map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(
              personFields.parishOfResidence[locale].name
            )}
          />
        )}

        {getAggBucketArray(searchResults, personFields.townOfResidence[locale].agg).length > 0 && (
          <Select
            name={personFields.townOfResidence[locale].name}
            label={t(personFields.townOfResidence.label)}
            options={getAggBucketArray(searchResults, personFields.townOfResidence[locale].agg).map((field) => {
              return {
                value: field.key,
                name: `${field.key} (${field.doc_count})`,
              };
            })}
            selected={searchParams.get(
              personFields.townOfResidence[locale].name
            )}
          />
        )}
      </Collapsible>
    </>
  );
};
