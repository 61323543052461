export type PlaceType = {
  town_village:
    | {
        town_village_name_en: string;
        town_village_name_ga: string;
        town_village_name_gd: string;
      }
    | {};
  parish:
    | {
        parish_name_en: string;
        parish_name_ga: string;
        parish_name_gd: string;
      }
    | {};
  barony_district:
    | {
        barony_district_name_en: string;
        barony_district_name_ga: string;
        barony_district_name_gd: string;
      }
    | {};
  county:
    | {
        county_name_en: string;
        county_name_ga: string;
        county_name_gd: string;
      }
    | {};
  region:
    | {
        region_name_en: string;
        region_name_ga: string;
        region_name_gd: string;
      }
    | {};
  country:
    | {
        country_name_en: string;
        country_name_ga: string;
        country_name_gd: string;
      }
    | {};
};

export type PersonType = {
  canonical_name: string;
  name: { name: string }[];
  place_of_birth?: PlaceType;
  place_of_residence?: PlaceType;
};

type CollectionEventType = {
  eventrole: { person: PersonType; role: string }[];
  place_of_collection?: PlaceType;
  collection_date: string;
  collection_range_start_date: string;
  collection_range_end_date: string;
};

type PersonRoleType = {
  place_of_residence?: PlaceType;
  role: string;
  residence_accuracy: boolean;
  additional_information: string;
  relationship: string;
  school_college_universiy: Object[]; // TODO: Define this type
  occupation: Object[];
  related_date_en: string;
  related_date_ga: string;
  related_date_gd: string;
  student_class_en: string;
  student_class_ga: string;
  student_class_gd: string;
  teacher: string;
};
export type ESDocument = {
  [key: string]: any;
  es_document_type: 'documents';
  diss_id: string;
  new_id: string;
  fenian: boolean;
  title: string;
  collection: { abbreviation: string; collection_name: string };
  collection_event: CollectionEventType;
  doc_language: { language: string }[];
  document_witness: { number: string; type: 'm' | 'r' }[];
  institution: { institution_name: string; institution_abbreviation: string };
  digitized: boolean;
};

export type ESPerson = {
  [key: string]: any;
  es_document_type: 'people';
  id: number;
  canonical_name: string;
  name: { name: string }[];
  place_of_birth?: PlaceType;
  bigraphy_en: string;
  biography_ga: string;
  biography_gd: string;
  description: string;
  date_of_birth: string;
  date_of_death: string;
  sort_name: string;
  personrole: PersonRoleType[];
  associated_documents: { title: string }[];
};

export type ESLayType = {
  [key: string]: any;
  alternative_titles: {
    alt_title_en: string;
    alt_title_ga: string;
    alt_title_gd: string;
  }[];
  es_document_type: 'laytpes';
  fenian_tale_type: string;
  further_information: string;
  international_tale_type: string;
  plot_summary_en: string;
  plot_summary_ga: string;
  plot_summary_gd: string;
  related_document_titles: string;
  slug: string;
  title_en: string;
  title_ga: string;
  title_gd: string;
  documents_count: number;
};

export type BucketType = {
  key: string;
  doc_count: number;
  key_as_string: string;
  labels: {
    hits: {
      hits: HitType[];
    };
  };
};

type HitType = {
  _index: string;
  _id: string;
  _score: number;
  _source: ESDocument | ESPerson | ESLayType;
};

type NamedBuckets = {
  [key: string]: BucketType;
};

type AggregationType = {
  [key: string]: {
    doc_count_error_upper_bound: number;
    sum_other_doc_count: number;
    buckets: NamedBuckets | BucketType[];
  };
};

export type ElasticResponse = {
  timed_out: boolean;
  _shards: {
    total: number;
    successful: number;
    skipped: number;
    failed: number;
  };
  hits: {
    max_score: number;
    timed_out: boolean;
    took: number;
    total: {
      value: number;
      relation: string;
    };
    hits: HitType[];
  };
  aggregations: AggregationType;
  pagination: {
    page: number;
    max_page: number;
  };
  totals: {
    [key: string]: number;
  };
};


export function getAggBucketArray(searchResults: ElasticResponse, aggName: string): BucketType[] {
  return searchResults.aggregations[aggName].buckets as BucketType[];
}

export function getAggBucket(searchResults: ElasticResponse, aggName: string, bucketNameOrIndex: string | number): BucketType {
  if (Array.isArray(searchResults.aggregations[aggName].buckets)) {
    return searchResults.aggregations[aggName].buckets[bucketNameOrIndex as number];
  }
  return searchResults.aggregations[aggName].buckets[bucketNameOrIndex as string];
}
