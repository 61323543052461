import React from 'react';
import styled from 'styled-components';
import { Collapsible } from '../components/Collapsible';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

//--------------- CHECKBOX COMPONENTS ---------------//
// --------------------------------------------------//
interface CheckBoxGroupWrapperProps {
  indent: boolean;
}

const CheckBoxGroupWrapper = styled.div<CheckBoxGroupWrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: ${(props) => (props.indent ? '0 1rem' : '1rem 0')};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

const CheckboxInput = styled.input`
  min-width: 1.15rem;
  min-height: 1.15rem;
  accent-color: #3f51b5;
  border: 3px solid #848484;
  border-radius: 15%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;

  &:checked {
    background-color: #3f51b5;
    border-color: #3f51b5;
  }

  &:checked::after {
    content: '';
    border-radius: 15%;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.6rem;
    height: 0.3rem;
    border: solid white;
    border-width: 0 0.16rem 0.16rem 0;
    transform: translate(-50%, -70%) rotate(130deg) scaleY(-1);
  }
`;

export const Checkbox: React.FC<{
  name: string;
  label: string;
  checked: boolean;
  value?: string
}> = ({ name, label, value = "true", checked = false }) => {
  const [isChecked, setIsChecked] = useState(checked);
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const checkboxId = `${name}-${value}-checkbox`;

  return (
    <CheckboxWrapper>
      <CheckboxInput
        name={name}
        id={checkboxId}
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        {...(value && { value: value })}
      />
      <label htmlFor={checkboxId}>{label}</label>
    </CheckboxWrapper>
  );
};

export const CheckboxGroup: React.FC<{
  checkboxes: {
    name: string;
    label: string;
    checked: boolean;
    value?: string;
  }[];
  indent?: boolean;
}> = ({ checkboxes, indent = false }) => {
  return (
    <CheckBoxGroupWrapper indent={indent}>
      {checkboxes.map((checkbox) => (
        <Checkbox
          key={checkbox.name + checkbox.value}
          name={checkbox.name}
          label={checkbox.label}
          checked={checkbox.checked}
          {...(checkbox.value && { value: checkbox.value })}
        />
      ))}
    </CheckBoxGroupWrapper>
  );
};

//---------------- SELECT COMPONENTS ----------------//
// --------------------------------------------------//

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0.5rem 0;
`;

const SelectInput = styled.select<{ hasValue: boolean }>`
  width: 100%;
  padding: 10px 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  color: #495057;
  appearance: none; /* Hide the default arrow */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    border-color: #3f51b5;
    box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2);
    outline: none;
  }

  &:hover {
    border-color: #3f51b5;
  }

  &:disabled {
    background-color: #e9ecef;
    color: #6c757d;
  }
`;

const Label = styled.label<{ hasValue: boolean }>`
  position: absolute;
  width: ${({ hasValue }) => (hasValue ? '' : '90%')};
  top: ${({ hasValue }) => (hasValue ? '-10px' : '50%')};
  left: 14px;
  transform: translateY(${({ hasValue }) => (hasValue ? '0' : '-50%')});
  background-color: #fff;
  padding: 0 4px;
  transition: 0.2s;
  pointer-events: none;
  font-size: ${({ hasValue }) => (hasValue ? '12px' : '16px')};
  color: #495057;
`;

const Arrow = styled.div`
  position: absolute;
  top: 55%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 16px;
  color: #6b6b6b;
`;

export const Select: React.FC<{
  name: string;
  label: string;
  options: { name: string; value: string }[];
  selected?: string | null;
  defaultOption?: { name: string; value: string; };
}> = ({ name, label, options, selected, defaultOption }) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(selected || '');
  const [hasValue, setHasValue] = useState<boolean>(Boolean(selected));

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedValue(value);
    setHasValue(value !== '');
  };

  useEffect(() => {
    setHasValue(selectedValue !== '');
  }, [selectedValue]);

  return (
    <SelectWrapper>
      <Label htmlFor={name} hasValue={hasValue}>{label}</Label>
      <SelectInput
        name={name}
        id={name}
        value={selectedValue || ''}
        hasValue={hasValue}
        onChange={handleChange}
      >
        {defaultOption ? (
            <option value={defaultOption.value}>{defaultOption.name}</option>
          ) : (
            <option value="">Reset</option>
          )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </SelectInput>
      <Arrow>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="20" strokeWidth="1.5" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
        </svg>
      </Arrow>
    </SelectWrapper>
  );
};
