import { useAPIBase } from './use-api-base';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ElasticResponse } from '../types/ElasticResponse';

const useQueryParams = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  return params;
};

const useSearch = () => {
  const base = useAPIBase();
  const params = useQueryParams();
  const apiURL = new URL(`${base}/search/`);
  apiURL.search = params.toString();

  // useQuery takes a key for caching; we're sorting the params so that the same search result is cached regardless of the order
  const sortedParamsKey = Array.from(params.entries()).sort().toString();

  return useQuery<ElasticResponse, Error>(
    ['search', sortedParamsKey], // Use sortedParamsKey as a dependency
    async () => {
      const response = await fetch(apiURL.toString());
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      return response.json();
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
};

export default useSearch;
