import React, { useState, useEffect } from 'react';
import { Dropdown } from './Dropdown';
import { useTranslation } from 'react-i18next';
import { usePages } from '../hooks/use-pages';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const defaultLanguageCode = 'en';
  // Directly set the language to the value stored in local storage, or to English as the default
  const [langCode, setLangCode] = useState(
    localStorage.getItem('language') || defaultLanguageCode
  );
  const resources = usePages(langCode);

  useEffect(() => {
    i18n.changeLanguage(langCode);
  }, [langCode, i18n]);

  const options = [
    { name: i18n.t('Irish'), value: 'ga' },
    { name: i18n.t('Scottish Gaelic'), value: 'gd' },
    { name: i18n.t('English'), value: 'en' },
  ];

  const changeLanguage = (code: string) => {
    localStorage.setItem('language', code);
    setLangCode(code);
  };

  const getLanguageName = (code: string) => {
    const languageOption = options.find(option => option.value === code);
    return languageOption ? languageOption.name : i18n.t('English');
  };

  return (
    <Dropdown
      key={langCode}
      options={options}
      selected={langCode}
      onChange={(e: any) => changeLanguage(e)}
      selectProps={{
        'aria-label': i18n.t('Select language')
      }}
    />
  );
};
