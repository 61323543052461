import React from 'react';
import i18n from 'i18next';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)(({ theme, variant }: { theme: Theme; variant?: string }) => ({
  ...(variant ? {
    minWidth: '30%',
    margin: '0.25rem 0',
  } : {
    minWidth: '8rem',
    color: 'white',
    '& svg': {
      color: 'white',
    },
    '& .MuiInput-underline::before': {
      borderBottom: 'none',
    },
    '& .MuiFormControl-root': {
      borderBottom: '0.5px solid white',
    },
  })
}));

type DropdownProps<T> = {
  options: Array<{ name: string; value: string }>;
  placeholder?: T | null;
  onChange: (val: T) => void;
  selected?: T;
  varient?: 'outlined' | 'standard' | 'filled' | undefined;
  allowNoneOption?: boolean;
  fixedWidth?: number;
  selectProps?: { [key: string]: any }; // Changed from string to any to allow for complex props
};

export const Dropdown = <T extends string>({
  options,
  placeholder,
  onChange,
  selected,
  varient,
  allowNoneOption,
  fixedWidth,
  selectProps
}: DropdownProps<T>) => {
  return (
    <StyledFormControl
      size="small"
      variant={varient}
    >
      <InputLabel
        sx={varient ? undefined : { color: 'white' }}
      >
        {placeholder ? placeholder : null}
      </InputLabel>
      <Select
        onChange={(ev: any) => onChange(ev && ev.target && ev.target.value ? ev.target.value : '')}
        label={placeholder}
        defaultValue=""
        value={selected || ''}
        sx={varient ? undefined : { color: 'white' }}
        inputProps={{
          'aria-label': placeholder || 'select-input',
          'data-testid': 'dropdown-select',  // Added for easier testing
          ...selectProps?.inputProps
        }}
        {...selectProps}
      >
        {allowNoneOption && (
          <MenuItem key="none_selected" value="">
            <em>{i18n.t('None selected')}</em>
          </MenuItem>
        )}
        {options.map((option, index) => {
            return (
              <MenuItem 
                key={option.value}
                value={option.value}
                style={fixedWidth ? {width: `${fixedWidth}px`, whiteSpace: 'unset'} : {}}
              >{option.name}
              </MenuItem>);
          })
        }
      </Select>
    </StyledFormControl>
  );
};
