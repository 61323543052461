import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../hooks/use-locale';
import { getPlaceNames } from '../utils/getPlaceNamePriority';
import {
  DisplayCardContainerLinkResults,
  DisplayCardTitle,
  DisplayCardIcon,
} from './DisplayCard';
import { PaddingComponent } from '../atoms/PaddingComponent';
import { Grid, GridColumn } from './Grid';
import { Id, SongStoryTitleContainer } from './SongStory.style';
import { TileDetail } from './TileDetail';
import { ESPerson, ESDocument } from '../types/ElasticResponse';
import { PersonIcon } from '../icons/CardIcons';


interface PersonCardProps {
  result: ESPerson;
}

export const PersonCard: React.FC<PersonCardProps> = ({ result }) => {
  const locale = useLocale() || 'en';
  const { t } = useTranslation();

  function getPersonName(person: ESPerson): string {
    if (!person.canonical_name && !person.name) return t("U");
    return (
      person.canonical_name ||
      Object.values(person.name)
        .filter(name => name.name.includes(locale))
        .join(', ')
    );
  }

  function getAllNames(person: ESPerson): string[] {
    return person.name
      .filter(name => name.name !== person.canonical_name)
      .map(name => name.name);
  }

  function getPersonRoles(person: ESPerson): Set<string> {
    return new Set(person.personrole.map(role => role.role));
  }

  function getPlacesOfResidence(person: ESPerson): string[] {
    if (!person.personrole || person.personrole.length === 0) return [];
    return Array.from(new Set(person.personrole.flatMap(role => {
      if (!role.place_of_residence) return [];
      return getPlaceNames(role.place_of_residence, locale);
    })));
  }

  const otherNames = getAllNames(result);
  const roles = getPersonRoles(result);
  const placesOfResidence = getPlacesOfResidence(result);

  return (
    <DisplayCardContainerLinkResults to={`/people/${result.id}`} target={"_blank"} rel={"noopener noreferrer"} backgroundcolor='#efffee'>
      <SongStoryTitleContainer>
        <DisplayCardTitle>{result.canonical_name || t('U')}</DisplayCardTitle>
        <DisplayCardIcon>
        {t('Person')}
        <PersonIcon/>
        </DisplayCardIcon>
      </SongStoryTitleContainer>
      <PaddingComponent/>
      <Grid>
        <GridColumn>
            {otherNames.length > 0 && (
                <TileDetail label={t('Also known as')}>
                  {otherNames.map((name, index) => (
                    <div key={index}>{name}</div>
                  ))}
                </TileDetail>
            )}

            {roles && (
                <TileDetail label={t('Roles')}>
                  {[...roles].map((role, index) => (
                    <div key={index}>{role}</div>
                  ))}
                </TileDetail>
            )}

            {result.date_of_birth && (
                <TileDetail label={t('Date of Birth')}>
                  {result.date_of_birth}
                </TileDetail>
            )}

            {result.date_of_death && (
                <TileDetail label={t('Date of Death')}>
                  {result.date_of_death}
                </TileDetail>
            )}

            {placesOfResidence.length > 0 && placesOfResidence[0] && (
                <TileDetail label={t('Place of Residence')}>
                  {placesOfResidence.map((place, index) => (
                    <div key={index}>{place}</div>
                  ))}
                </TileDetail>
            )}

        </GridColumn>
      </Grid>
    </DisplayCardContainerLinkResults>
  );
}
