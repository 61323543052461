import { Link } from 'react-router-dom';
import styled from 'styled-components';


export const DisplayCardIcon = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  margin-left: 0.5rem;
`;

export const DisplayCardTitle = styled.div`
   {
    font-size: 20px;
    color: #000000;
    text-decoration: rgb(0, 0, 0);
    line-height: 20px;
    margin-right: 0.5rem;
  }
`;

export const InTextDisplayCardTitle = styled.div`
   {
    font-size: 20px;
    color: #000000;
    text-decoration: rgb(0, 0, 0);
    line-height: 20px;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`;

export const DisplayCardContainer = styled.div`
   {
    padding: 1rem 1.375rem;
    min-height: 182px;
    width: 44%;
    border-radius: 4px;
    background-color: #ffffff;
    background-size: cover;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    margin: 1rem 0rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const FullWidthDisplayCardContainer = styled(DisplayCardContainer)`
   {
    min-height: 100px;
    width: 100%;
  }
`;

export const ThirdWidthDisplayCardContainer = styled(DisplayCardContainer)`
  {
    width: 28%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const DisplayCardContainerLink = styled(Link)`
  padding: 1rem 1.375rem;
  width: 44%;
  border-radius: 4px;
  background-color: #ffffff;
  background-size: cover;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  text-decoration: none;
  margin: 1rem 0rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const DisplayCardContainerLinkSmall = styled(DisplayCardContainerLink)`
   {
    justify-content: unset;
    min-height: 80px;
  }
`;

interface DisplayCardContainerLinkResultsProps {
  backgroundcolor?: string;
}
export const DisplayCardContainerLinkResults = styled(DisplayCardContainerLink)<DisplayCardContainerLinkResultsProps>`
    width: unset;
    background-color: ${props => props.backgroundcolor || 'none'};
    
    &:hover {
        filter: brightness(0.97);
    }
`;

export const DisplayCardSubtitle = styled.div`
   {
    font-size: 18px;
    color: rgba(0, 0, 0);
    line-height: 20px;
  }
`;

export const DisplayCardText = styled.div`
   {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
  }
`;

export const DisplayCardSubtitleItalics = styled(DisplayCardSubtitle)`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  font-style: italic;
`;
